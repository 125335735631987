import React, { useEffect, useState } from 'react';
import Logo from './avatar23_small.png';
import Colors from './Colors';
import AndrfwLink from './AndrfwLink';
import Card from './Card';
import { Link, useLocation } from 'react-router-dom'
import { getRandomPage } from './RandomUtil';

const breakpoints = {
    tiny: 0,
    small: 460,
    large: 640,
}

const getBreakpointFromWidth = (width) => {
    return Object.keys(breakpoints)
        .sort((a, b) => breakpoints[b] - breakpoints[a])
        .find((e) => breakpoints[e] < width);
}

const NO_HEADER_PAGES = ["/", "/pickleball"];

export default function Header(props) {
    const [breakpoint, setBreakpoint] = useState(getBreakpointFromWidth(window?.innerWidth));
    //const [isExpanded, setIsExpanded] = useState(false);
    const randomPage = getRandomPage(props.randomPages, window.location.pathname);

    useEffect(() => {
        const onResize = () => {
            setBreakpoint(getBreakpointFromWidth(window?.innerWidth));
        }

        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, []);

    const { pathname } = useLocation();
    if (NO_HEADER_PAGES.includes(pathname)) {
        return null;
    }

    return <div style={{
        padding: '5px 10px',
        boxShadow: '0 0 8px rgba(33,33,33,.3)',
        zIndex: 1,
        position: 'relative',
    }}
    >
        <div style={{
            display: 'flex',
            justifyContent: breakpoints[breakpoint] <= breakpoints.small ? 'center' : 'space-between',
            flexDirection: breakpoints[breakpoint] <= breakpoints.small ? 'column' : 'row',
            alignItems: 'center',
        }}>
            { breakpoints[breakpoint] > breakpoints.small && <Link to="/">
                <img src={Logo} alt="Logo" style={{height: '64px'}}/>
            </Link>
}
            <HeaderCardRow randomPage={randomPage}/> 
        </div>
    </div>
}

function HeaderCardRow({randomPage}) {
    return <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
            <HeaderCard color={Colors.red} label="Games" link={'/games'}/>
            {/* <HeaderCard color={Colors.orange} label="Comics" link={'/comics'}/>
            <HeaderCard color={Colors.yellow} label="Music" link={'/music'}/> */}
            <HeaderCard color={Colors.green} label="Blog" link={'/blog'}/>
            <div style={{display: 'flex'}}>
                <HeaderCard color={Colors.blue} label="About" link={'/about'}/>
                <HeaderCard color={Colors.purple} label="Random" link={randomPage.link} outlink={randomPage.outlink}/>
            </div>
        </div>;
}

function HeaderCard(props) {
    return <div style={{ margin: '5px' }}>
        <AndrfwLink link={props.link} outlink={props.outlink}>
            <Card color={props.color} style={{width: "100px", height: "50px"}}>
                <div 
                    className={"andrfw-text"}
                    style={{
                        fontSize: '1.2em',
                        color: Colors.white,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                    }}
                >
                    {props.label}
                </div>
            </Card>
        </AndrfwLink>
    </div>;
}

