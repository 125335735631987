import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import Card from './Card';
import Colors from './Colors';
import { MetadataContext } from './Contexts';
import NoPage from './NoPage';
import { useParams } from 'react-router-dom';

function pad(num) {
    var str = String(num);
    const zeroes = 3 - str.length;
    for (var i = 0; i < zeroes; i++) {
        str = "0" + str;
    }
    return str;
}

function ComicPage() {
    const comicName = useParams('comicname').comicname;
    let pageNumberInt = parseInt(useParams('comicpage').comicpage, 10);
    pageNumberInt = isNaN(pageNumberInt) ? -1 : pageNumberInt;
    const isFirstPage = (pageNumberInt === 1);
    const metadataContext = useContext(MetadataContext);

    if (!metadataContext) {
        return null;
    } else if (!metadataContext.meta.comics[comicName] ||
        pageNumberInt > metadataContext.meta.comics[comicName].lastPage ||
        pageNumberInt <= 0
    ) {
        return <NoPage/>;
    }

    const metadata = metadataContext.meta.comics[comicName]
    let chapterName = "";
    if (metadata.chapters) {
        const validChapters = metadata.chapters
            .filter(x => x.firstPage <= pageNumberInt)
            .sort((a, b) => a.firstPage - b.firstPage);
        chapterName = validChapters[validChapters.length - 1].name;
    }
    const isLastPage = (pageNumberInt === metadata.lastPage);

    const nextPageNumber = isLastPage ? pageNumberInt : pageNumberInt + 1;
    const prevPageNumber = pageNumberInt - 1;

    return (
        <div style={{display: "flex", justifyContent: "center", marginTop: '10px'}}>
            <div className="andrfw-link-list-container">
                <Link to={`/comics/${comicName}`}>
                    <img src={`https://andrfw-site.s3.amazonaws.com/comics/${comicName}/header.png`} alt="header"/>
                </Link>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <div className="andrfw-small-text">{chapterName}</div>
                    <Link to={`/comics/${comicName}/pages/${nextPageNumber}`}>
                        <div className="andrfw-comicpage-image">
                            <img 
                                src={`https://andrfw-site.s3.amazonaws.com/comics/${comicName}/pages/${pad(pageNumberInt)}.png`}
                                alt="Page"
                            />
                        </div>
                    </Link>
                    <div style={{display: "flex", justifyContent: "center", margin: "10px"}}>
                        <Button to={`/comics/${comicName}/pages/1`} label="First" color={Colors.red} disabled={isFirstPage}/>
                        <Button to={`/comics/${comicName}/pages/${prevPageNumber}`} label="Back" color={Colors.orange} disabled={isFirstPage}/>
                        <Button to={`/comics/${comicName}/pages/${nextPageNumber}`} label="Next" color={Colors.green} disabled={isLastPage}/>
                        <Button to={`/comics/${comicName}/pages/${metadata.lastPage}`} label="Last" color={Colors.blue}  disabled={isLastPage}/>
                    </div>
                </div>
                <Link 
                    to={`/comics/${comicName}`}
                    className="andrfw-small-link"
                >
                    Table of Contents
                </Link>
            </div>
        </div>
    );
}

function Button(props) {
    const card = <Card 
        style={{
            width: '60px',
            height: '40px',
            marginLeft: "15px",
            marginRight: "15px"
        }}
        color={props.color}
        disabled={props.disabled}
    >
        <div
            style={{
                display: 'flex',
                alignItems: 'center', 
                justifyContent: 'center',
                height: '100%', 
                color: 'white',
                fontSize: '1em'
            }}
            className="andrfw-text"
        >
            {props.label}
        </div>
    </Card>

    if (props.disabled) {
        return card;
    } else {
        return <Link 
            to={props.to}
            className="andrfw-small-link"
        >
            {card}
        </Link>
    }
}

export default ComicPage;
