const colors = {
    red: '#ff4044',
    orange: '#ff993c',
    yellow: '#ffd64c',
    green: '#00d88b',
    blue: '#199afe',
    purple: '#b768ff',
    lightblue: '#86c7ff',
    darkblue: '#003e80',
    white: '#ffffff',
}

export default colors;

export function getColorByIndex(index) {
    const colorsArray = [
        colors.red,
        colors.orange,
        colors.yellow,
        colors.green,
        colors.blue,
        colors.purple,
    ]
    const safeIndex = ((index % colorsArray.length) + colorsArray.length) % colorsArray.length;
    return colorsArray[safeIndex];
}