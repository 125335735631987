import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import BackButton from "./BackButton";
import NoPage from './NoPage';

export default function BlogPage() {
    const blogName = useParams('blogname').blogname;
    const [blogData, setBlogData] = useState(undefined);

    useEffect(() => {
        fetch(`https://andrfw-site.s3.amazonaws.com/blog/${blogName}.html`)
            .then(x => {
                if (!x.ok) {
                    throw new Error();
                }
                return x.text();
            })
            .then(x => setBlogData({__html: x}))
            .catch(() => setBlogData(null))
    }, [blogName]);

    if (blogData === undefined) {
        return null;
    }

    if (blogData === null) {
        return <NoPage/>
    }

    return (
        <div className="andrfw-link-list-container" style={{marginLeft: '10px', marginRight: '10px'}}>
            {blogData && <div className="andrfw-dynamic-content" dangerouslySetInnerHTML={blogData}/>}
            <BackButton/>
        </div>
    );
}