import React from 'react';
import AndrfwLink from "./AndrfwLink";
import Colors from './Colors.js';
import Card from './Card';
import logo from "./avatar23_withname.png";
import { getRandomPage } from './RandomUtil';

function Home(props) {
    const randomPage = getRandomPage(props.randomPages, window.location.pathname);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <img src={logo} alt="Logo" style={{margin: '20px'}}/>
            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                maxWidth: '400px',
                justifyContent: 'center',
            }}>
                <HomepageCard color={Colors.red} label="Games" link={'/games'}/>
                <HomepageCard color={Colors.green} label="Blog" link={'/blog'}/>
                <HomepageCard color={Colors.blue} label="About" link={'/about'}/>
                <HomepageCard color={Colors.purple} label="Random" link={randomPage.link} outlink={randomPage.outlink} />
            </div>
        </div>
    );
}

const HomepageCard = (props) => {
    return <div style={{ margin: '10px' }}>
        <AndrfwLink link={props.link} outlink={props.outlink}>
            <Card color={props.color} style={{width: "180px", height: "100px"}}>
                <div 
                    className={"andrfw-text"}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: Colors.white,
                        height: '100%',
                    }}
                >
                    {props.label}
                </div>
            </Card>
        </AndrfwLink>
    </div>;
}

export default Home;
