import React from 'react';
import BackButton from './BackButton';
import SelfPortrait from './self_portrait.png';
import Button from './Button';
import Colors from './Colors';
import AndrfwLink from './AndrfwLink';

export default function About() {

    const rowStyle = {
        textAlign: 'left',
        padding: '10px'
    }
    
    return <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
        <div style={{marginBottom: '20px', maxWidth: '650px'}}>
            <div className="andrfw-text" style={{marginTop: '20px', marginBottom: '10px'}}>
                About
            </div>

            <div style={{display: 'flex'}}>
                <div style={{minWidth: '130px', display: 'flex', alignItems: 'center', margin: '20px'}}>
                    <img src={SelfPortrait} alt="self portrait" />
                </div>
                <div >
                    <div className="andrfw-small-text" style={rowStyle}>
                            Hi! I'm Andrew Murray, a Seattle-based hobbyist gamedev. I specialize in making short, narrative games for the web, but I'm always experimenting outside my comfort zone as well!
                    </div>

                    <div className="andrfw-small-text" style={rowStyle}>
                        In 2022, I released <AndrfwLink link={'/pickleball'}>Super Pickleball Adventure</AndrfwLink>, my best-known game.
                    </div>

                    <div className="andrfw-small-text" style={rowStyle}>
                        I tend to collaborate with a cool group of friends including{" "}
                        <AndrfwLink link={'https://notexplosive.itch.io/'} outlink>NotExplosive</AndrfwLink>,{" "}
                        <AndrfwLink link={'https://lectvs.itch.io/'} outlink>Lectvs</AndrfwLink>,{" "}
                        <AndrfwLink link={'https://joespacio.itch.io/'} outlink>Jose&nbsp;Pacio</AndrfwLink>,{" "}
                        <AndrfwLink link={'https://ursagames.itch.io/'} outlink>Ursagames</AndrfwLink>,{" "}
                        <AndrfwLink link={'https://diency.itch.io/'} outlink>Diency</AndrfwLink>,{" "}
                        <AndrfwLink link={'https://sam-long.itch.io/'} outlink>Sam&nbsp;Long</AndrfwLink>, and{" "}
                        <AndrfwLink link={'https://kai-bubble.itch.io/'} outlink>Kai_Bubble</AndrfwLink>.
                    </div>
                </div>
            </div>
            <div>
                <div className="andrfw-small-text" style={rowStyle}>
                            Back in 2017, I ran the game development club at the University of Washington where I set up a program to help first-time gamedevs build, refine, and exhibit a game in their first 10 weeks.
                </div>

                <div className="andrfw-small-text" style={rowStyle}>
                        Don't know where to start? You can hit the random button in the corner to get sent to a random thing I've made!
                </div>

                <div className="andrfw-small-text" style={rowStyle}>
                        I'm always updating with newer, cooler projects, so stay tuned! You can stay up-to-date by subscribing to my newsletter or following me on various platforms:
                </div>

                <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                    <AndrfwLink link={'https://tinyletter.com/andrfw'} outlink>
                        <Button color={Colors.green} label="Newsletter"/>
                    </AndrfwLink>
                    <AndrfwLink link={'https://twitter.com/andrfw_games'} outlink>
                        <Button color={Colors.blue} label="Twitter"/>
                    </AndrfwLink>
                    <AndrfwLink link={'https://www.instagram.com/andrfw/'} outlink>
                        <Button color={Colors.purple} label="Instagram"/>
                    </AndrfwLink>
                    <AndrfwLink link={'https://andrfw.newgrounds.com/'} outlink>
                        <Button color={Colors.orange} label="Newgrounds"/>
                    </AndrfwLink>
                    <AndrfwLink link={'https://andrfw.itch.io/'} outlink>
                        <Button color={Colors.red} label="itch.io"/>
                    </AndrfwLink>
                    {/* <AndrfwLink link={'https://soundcloud.com/andrfw'} outlink>
                        <Button color={Colors.orange} label="SoundCloud"/>
                    </AndrfwLink> */}
                </div>
            </div>
        </div>
        <BackButton/>
    </div>
}