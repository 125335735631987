import React, {useContext} from 'react';
import BackButton from './BackButton.js';
import AndrfwLink from './AndrfwLink';
import Card from './Card';
import Colors, { getColorByIndex } from './Colors';
import { MetadataContext } from './Contexts.js';
import NoPage from './NoPage.js';
import { useParams } from 'react-router-dom';

function ComicTableOfContents() {
    const comicName = useParams('comicname').comicname;
    const metadataContext = useContext(MetadataContext);

    if (!metadataContext) {
        return null;
    } else if (!metadataContext.meta.comics[comicName]) {
        return <NoPage/>;
    }

    const metadata = metadataContext.meta.comics[comicName];
    
    let chapterButtons = null;
    let firstLastButtons = null;
    if (metadata) {
        if (metadata.chapters) {
            chapterButtons = metadata.chapters.map((x, idx) => ({pageNumber: x.firstPage, label: x.name, color: getColorByIndex(idx)}));
        }
        firstLastButtons = [
            {pageNumber: 1, label: "First Page", color: Colors.red},
            {pageNumber: metadata.lastPage, label: "Latest Page", color: Colors.blue}
        ];
    }

    return (
        <div className = "andrfw-link-list-container">
            <img src={`https://andrfw-site.s3.amazonaws.com/comics/${comicName}/cover.png`} alt="cover"/>
            { metadata && <div>
                <ButtonGroup comicName={comicName} buttons={firstLastButtons}/>
                { metadata.chapters && <div>
                    <div className={"andrfw-text"} style={{fontSize: '1.6em'}}>Bookmarks</div>
                    <ButtonGroup comicName={comicName} buttons={chapterButtons}/>
                </div> }
            </div> }
            <BackButton/>
        </div>
    );
}

function ButtonGroup(props) {
    return <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '700px', justifyContent: 'center'}}>
        { props.buttons.map((x, idx) => <AndrfwLink key={idx} link={`/comics/${props.comicName}/pages/${x.pageNumber}`}>
        <Card color={x.color} style={{width: '160px', height: '60px', margin: '10px'}}>
                <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div className="andrfw-text" style={{color: Colors.white, fontSize: '1.2em', padding: '10px'}}>
                        {x.label}
                    </div>
                </div>
        </Card>
    </AndrfwLink> )} 
</div>
}

export default ComicTableOfContents;
