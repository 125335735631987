import React from 'react';
import AndrfwLink from './AndrfwLink';
import PropTypes from 'prop-types';

function AndrfwTextLink(props) {
    const className = props.size === "sm" ? "andrfw-small-link" : "andrfw-link";

    return <AndrfwLink link={props.link} outlink={props.outlink}>
        <div className={className}>{props.label}</div>
    </AndrfwLink>;
}

AndrfwTextLink.propTypes = {
    link: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    outlink: PropTypes.bool,
    size: PropTypes.string,
};

AndrfwTextLink.defaultProps = {
    size: "sm",
};

export default AndrfwTextLink;