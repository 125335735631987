import React, {useState, useEffect} from 'react';
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import Home from './Home.js'
import IndexPage from './IndexPage'
import GamePage from "./GamePage";
import BlogPage from './BlogPage';
import ComicPage from "./ComicPage";
import ComicTableOfContents from './ComicTableOfContents.js';
import NoPage from './NoPage.js';
import { MetadataContext }  from './Contexts.js';
import Header from './Header';
import { getDefaultRandomPage, getAllRandomPages } from './RandomUtil.js';
import About from './About';
import PickleballPage from './PickleballPage.js';
import ScrollToTop from './ScrollToTop.js';
import { Analytics } from '@vercel/analytics/react';

export default function App() {
    const [randomPages, setRandomPages] = useState([getDefaultRandomPage()]);
    const [metadata, setMetadata] = useState(null);

    useEffect(() => {
        fetch("https://andrfw-site.s3.amazonaws.com/misc/metadata.json")
            .then(x => x.json())
            .then(x => {
                setRandomPages(getAllRandomPages(x));
                setMetadata(x);
            });
    }, []);
    
    return (
        <>
        <Analytics />
        <MetadataContext.Provider value={metadata}>
            <BrowserRouter>
                <ScrollToTop />
                <Header randomPages={randomPages} />
                <Routes>
                    <Route exact path="/games" element={<IndexPage title="Games" section="games"/>}/>
                    <Route exact path='/games/:gamename' element= {<GamePage />}/>
                    <Route exact path="/comics" element={<IndexPage title="Comics" section="comics"/>}/>
                    <Route exact path={'/comics/:comicname'} element={<ComicTableOfContents />}/>
                    <Route exact path={'/comics/:comicname/pages/:comicpage'} element={<ComicPage />}/>
                    <Route exact path="/music" element={<IndexPage title="Music" section="music"/>}/>
                    <Route exact path="/blog" element={<IndexPage title="Blog" section="blog"/>}/>
                    <Route exact path='/about' element={<About />}/>
                    <Route exact path='/blog/:blogname' element= {<BlogPage />}/>
                    <Route exact path="/pickleball" element={<PickleballPage />}/>
                    <Route exact path="/" element={<Home randomPages={randomPages} />}/>
                    <Route path="*" element={<NoPage />}/>
                </Routes>
            </BrowserRouter>
        </MetadataContext.Provider>
        </>
    );
}