import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import './Style.scss'

function AndrfwLink(props) {
    return (
        props.outlink
            ? <a href={props.link} style={{textDecoration: 'none'}}>{props.children}</a>
            : <Link to={props.link} style={{textDecoration: 'none'}}>{props.children}</Link>
    );
}

AndrfwLink.propTypes = {
    link: PropTypes.string.isRequired,
    outlink: PropTypes.bool,
};

export default AndrfwLink;