import React from 'react';
import Card from './Card';
import Colors from './Colors';

export default function Button(props) {
    return <Card color={props.color} style={{minWidth: '100px', height: '50px', margin: '10px'}}>
        <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div className="andrfw-text" style={{color: Colors.white, fontSize: '1em', padding: '10px'}}>
                {props.label}
            </div>
        </div>
    </Card>;
}