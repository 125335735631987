import React from 'react';
import PickleballLogo from './pickleball_logo.png'
import SpaceBackground from './space_background_just_space.png'
import AndrfwLink from './AndrfwLink'
import Button from './Button'
import Colors from './Colors'

export default function PickleballPage() {
    const currentTime = Date.now();
    const releaseTime = Date.parse("01 Jan 2022 10:00:00 PST");
    const gameIsReleased = (currentTime > releaseTime);

    return <div style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundImage: `url(${SpaceBackground})`,
        backgroundSize: '100%'
    }}>
        <div style={{width: 'default', padding: '20px'}}> 
            <img src={PickleballLogo} alt="Super Pickleball Adventure"/>
        </div>
        <FancyText> Find and defeat the three Pickleball Lords to become the Champion of Pickleball </FancyText>
        <FancyText sm> Challenge opponents that use a variety of techniques from ninjitsu to teleportation! Explore the world and meet a cast of wacky characters! </FancyText>
        <br/>
        { gameIsReleased  
            ? <FancyText> Play now in browser: <a href="https://www.newgrounds.com/portal/view/828011">newgrounds</a> | <a href="https://andrfw.itch.io/super-pickleball-adventure">itch</a></FancyText>
            : <FancyText> Free web game coming January 1st, 2022! </FancyText>
        }
        <br/>
        <br/>
        <div style={{width: '560px', height:'315px', maxWidth: '90vw', position: 'relative'}}>
            <iframe 
                style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}
                src="https://www.youtube.com/embed/pZ3muQm_jMg"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            />
        </div>
        <br/>
        <br/>
        { !gameIsReleased && <>
            <FancyText> To be emailed when the game is released, sign up for my newsletter: </FancyText>
            <AndrfwLink link={'https://tinyletter.com/andrfw'} outlink>
                <Button color={Colors.purple} label="Newsletter"/>
            </AndrfwLink>
        </> }
    </div> 
}

function FancyText(props) {
    return <div className="andrfw-text" style={{
        textAlign: 'center',
        color: 'white',
        width: '500px',
        maxWidth: '80vw',
        fontSize: props.sm ? '1em' : '1.5em'
    }}>
        {props.children}
    </div>
}