import React from 'react';
import Colors from './Colors';

export default function Card(props) {
    return <div style={{...props.style}}>
        <div className="andrfw-card-parent">
            <div
                className={props.disabled ? "andrfw-card-disabled" : "andrfw-card"}
                style={{backgroundColor: props.color || Colors.white}}
            >
                {props.children}
            </div>
        </div>
    </div>
}