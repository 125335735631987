import React, {useContext} from 'react';
import { useParams } from 'react-router-dom';
import BackButton from "./BackButton";
import { MetadataContext } from './Contexts';
import NoPage from './NoPage';

export default function GamePage() {
    const gameName = useParams('gamename').gamename;
    const metadataContext = useContext(MetadataContext);

    if (!metadataContext) {
        return null;
    } else if (!metadataContext.meta.games[gameName]) {
        return <NoPage/>;
    }


    const gameData = metadataContext.meta.games[gameName];

    return (
        <div className="andrfw-link-list-container">
            <div style={ {border: gameData.border ? "solid" : "none", marginTop: '20px'} }>
                <iframe 
                    title="game"
                    src={`https://andrfw-site.s3.amazonaws.com/games/${gameName}/game/index.html${window.location.search}`}
                    width={gameData.width}
                    height={gameData.height}
                    scrolling="no"
                    style={ {border: "none"} }
                />
            </div>
            <BackButton/>
        </div>
    );
}