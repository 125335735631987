export function getRandomPage(randomPages, path) {
    const possibleRandomPages = randomPages.filter((page) => page.link !== path);
    if (possibleRandomPages.length === 0) {
        return getDefaultRandomPage();
    }
    return possibleRandomPages[getRandomInt(possibleRandomPages.length)];
}

export function getDefaultRandomPage() {
    return {link: "/about", outlink: false};
}

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

export function getAllRandomPages(metadata) {
    const links = [];
    for (const section in metadata.links) {
        metadata.links[section].forEach((card) => links.push({
            link: card.link,
            outlink: card.outlink
        }));
    }
    return links;
}