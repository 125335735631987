import React, {Component} from 'react';
import AndrfwTextLink from "./AndrfwTextLink";

class BackButton extends Component {
    render() {
        return (
          <div style={{paddingBottom: '10px'}}>
            <AndrfwTextLink link={"/"} label="back to main page"/>
          </div>
        );
    }
}

export default BackButton;