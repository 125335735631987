import React, {useContext, useEffect, useState} from 'react';
import AndrfwLink from "./AndrfwLink";
import Card from "./Card";
import Colors from './Colors';
import { MetadataContext } from './Contexts';
import BackButton from "./BackButton";

const breakpoints = {
    tiny: 0,
    small: 300,
    large: 400,
}

const getBreakpointFromWidth = (width) => {
    return Object.keys(breakpoints)
        .sort((a, b) => breakpoints[b] - breakpoints[a])
        .find((e) => breakpoints[e] < width);
}

function IndexPage(props) {
    const metadataContext = useContext(MetadataContext);
    const [breakpoint, setBreakpoint] = useState(getBreakpointFromWidth(window?.innerWidth));
    useEffect(() => {
        const onResize = () => {
            setBreakpoint(getBreakpointFromWidth(window?.innerWidth));
        }

        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, []);

    const imageSize = {
        tiny: '80px',
        small: '120px',
        large: '160px',
    }[breakpoint];

    const imageTop = {
        tiny: '40px',
        small: '20px',
        large: '0px',
    }[breakpoint];

    const cardWidth = {
        tiny: '260px',
        small: '320px',
        large: '380px',
    }[breakpoint];


    if (!metadataContext) {
        return null;
    }

    const linkListData = metadataContext.links[props.section];

    return (
        <div
            className = "andrfw-link-list-container"
        >
            <div className="andrfw-text" style={{marginTop: '20px', marginBottom: '10px'}}>{props.title}</div>
            <div style={{display: 'flex', flexWrap: 'wrap', maxWidth: '880px', justifyContent: 'center'}}>
                { linkListData && linkListData
                    .sort((a, b) => a.firstPage - b.firstPage)
                    .map((x, idx) => <AndrfwLink key={idx} link={x.link} outlink={!!x.outlink}>
                        <Card color={(x.color && Colors[x.color]) || Colors.red} style={{width: cardWidth, height: '160px', margin: '10px'}}>
                            <div style={{display:'flex'}}>
                                { x.image && <div style={{minWidth: imageSize, maxWidth: imageSize, height: imageSize, marginLeft: '20px', marginTop: imageTop, backgroundColor: Colors.darkblue}}>
                                    <img src={x.image} alt=""/>
                                </div> }
                                <div style={{padding: '10px'}}>
                                    <div className="andrfw-text" style={{color: Colors.white, fontSize: '1.2em', textAlign: 'left'}}>
                                        {x.label}
                                    </div>
                                    { x.description && <div className="andrfw-small-text" style={{color: Colors.white, fontSize: '0.8em', textAlign: 'left', whiteSpace: 'pre-wrap'}}>
                                        {x.description}
                                    </div>}
                                </div>
                            </div>
                        </Card>
                    </AndrfwLink>)
                }
            </div>
            <BackButton/>
        </div>
    );
}

export default IndexPage;